import { FinanceAssignmentModule } from './modules/admin/finance/finance-assignment/finance-assignment.module';
import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },

  // Auth routes for guests
  {
    path: '',
    canMatch: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'parent-portal/parent-request',
        loadChildren: () =>
          import(
            'app/modules/parent/parent-request/parent-request.module'
          ).then((m) => m.ParentRequestModule),
      },
      {
        path: 'parent-portal/e-admission',
        loadChildren: () =>
          import('app/modules/parent/e-admission/e-admission.module').then(
            (m) => m.EadmissionModule
          ),
      },
      {
        path: 'parent-portal/centre-invite',
        loadChildren: () =>
          import('app/modules/parent/centre-invite/centre-invite.module').then(
            (m) => m.CentreInviteModule
          ),
      },
      {
        path: 'parent-portal/withdrawals',
        loadChildren: () =>
          import('app/modules/parent/withdrawal/parent-withdrawal.module').then(
            (m) => m.ParentWithdrawalModule
          ),
      },
      {
        path: 'parent-portal/graduate',
        loadChildren: () =>
          import('app/modules/parent/graduate/parent-graduate.module').then(
            (m) => m.ParentGraduateModule
          ),
      },
      {
        path: 'confirmation-required',
        loadChildren: () =>
          import(
            'app/modules/auth/confirmation-required/confirmation-required.module'
          ).then((m) => m.AuthConfirmationRequiredModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import(
            'app/modules/auth/forgot-password/forgot-password.module'
          ).then((m) => m.AuthForgotPasswordModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('app/modules/auth/reset-password/reset-password.module').then(
            (m) => m.AuthResetPasswordModule
          ),
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
      {
        path: 'sign-up',
        loadChildren: () =>
          import('app/modules/auth/sign-up/sign-up.module').then(
            (m) => m.AuthSignUpModule
          ),
      },
      {
        path: 'login-microsoft-sso/callback',
        loadChildren: () =>
          import(
            'app/modules/auth/microsoft-sso-callback/microsoft-sso-callback.module'
          ).then((m) => m.MicrosoftSsoCallbackModule),
      },
      {
        path: 'terms-and-conditions',
        loadChildren: () =>
          import(
            'app/modules/terms-and-condition/terms-and-condition.module'
          ).then((m) => m.TermsAndConditionModule),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },
  {
    path: '',
    canMatch: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'centre-management',
        loadChildren: () =>
          import('app/modules/admin/centre/centre-management.module').then(
            (m) => m.CentreManagementListModule
          ),
      },
      {
        path: 'staff',
        loadChildren: () =>
          import('app/modules/admin/staff/staff.module').then(
            (m) => m.StaffModule
          ),
      },
      {
        path: 'staff-team',
        loadChildren: () =>
          import('app/modules/admin/staff-team/staff-team.module').then(
            (m) => m.StaffTeamModule
          ),
      },
      {
        path: 'classes',
        loadChildren: () =>
          import('app/modules/admin/class/class.module').then(
            (m) => m.ClassModule
          ),
      },
      {
        path: 'schedules',
        loadChildren: () =>
          import('app/modules/admin/schedule/class-session.module').then(
            (m) => m.ClassSessionModule
          ),
      },
      // {
      //   path: 'academic-year',
      //   loadChildren: () =>
      //     import(
      //       'app/modules/admin/academic/academic-years/academic.module'
      //     ).then((m) => m.AcademicModule),
      // },
      {
        path: 'terms',
        loadChildren: () =>
          import('app/modules/admin/academic/term/term.module').then(
            (m) => m.TermModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import(
            'app/modules/admin/e-referral-calendar/e-referral-calendar.module'
          ).then((m) => m.EventCalendarModule),
      },
      {
        path: 'calendar-events',
        loadChildren: () =>
          import(
            'app/modules/admin/academic/calendar-event/calendar-event.module'
          ).then((m) => m.CalendarEventModule),
      },
      // {
      //     path: 'admission',
      //     loadChildren: () =>
      //         import('app/modules/admin/admission/admission.module').then(
      //             (m) => m.AdmissionModule
      //         ),
      // },
      {
        path: 'attendance',
        loadChildren: () =>
          import('app/modules/admin/attendance/attendance.module').then(
            (m) => m.AttendanceModule
          ),
      },
      // {
      //     path: 'admission/admission-process',
      //     loadChildren: () =>
      //         import('app/modules/admin/admission/admission.module').then(
      //             (m) => m.AdmissionModule
      //         ),
      // },
      // {
      //     path: 'admission/admission-process/:id',
      //     loadChildren: () =>
      //         import('app/modules/admin/admission/admission.module').then(
      //             (m) => m.AdmissionModule
      //         ),
      // },
      {
        path: 'broadcasts',
        loadChildren: () =>
          import('app/modules/admin/broadcast/broadcast.module').then(
            (m) => m.BroadcastModule
          ),
      },
      {
        path: 'chat-history',
        loadChildren: () =>
          import('app/modules/admin/chat-history/chat-history.module').then(
            (m) => m.ChatHistoryModule
          ),
      },
      {
        path: 'students',
        loadChildren: () =>
          import('app/modules/admin/student/student.module').then(
            (m) => m.StudentModule
          ),
      },
      {
        path: 'e-referral',
        loadChildren: () =>
          import('app/modules/admin/e-referral/e-referral.module').then(
            (m) => m.ReferralProcessModule
          ),
      },
      {
        path: 'withdrawals',
        loadChildren: () =>
          import('app/modules/admin/withdrawal/withdrawal.module').then(
            (m) => m.WithdrawalModule
          ),
      },
      {
        path: 'graduate',
        loadChildren: () =>
          import('app/modules/admin/graduate/graduate.module').then(
            (m) => m.GraduateModule
          ),
      },
      {
        path: 'iep',
        loadChildren: () =>
          import('app/modules/admin/IEP/IEP.module').then((m) => m.IEPModule),
      },
      {
        path: 'finance-settings',
        loadChildren: () =>
          import(
            'app/modules/admin/finance/finance-settings/finance-settings.module'
          ).then((m) => m.FinanceSettingModule),
      },
      {
        path: 'finance-assignment',
        loadChildren: () =>
          import(
            'app/modules/admin/finance/finance-assignment/finance-assignment.module'
          ).then((m) => m.FinanceAssignmentModule),
      },
      {
        path: 'internal-transfer',
        loadChildren: () =>
          import(
            'app/modules/admin/internal-transfer/internal-transfer.module'
          ).then((m) => m.InternalTransferModule),
      },
      {
        path: 'caregiver-coaching-home-visit',
        loadChildren: () =>
          import(
            'app/modules/admin/caregiver-coaching-home-visit/caregiver-coaching-home-visit.module'
          ).then((m) => m.CaregiverCoachingHomeVistiModule),
      },
      {
        path: 'caregiver-interview',
        loadChildren: () =>
          import(
            'app/modules/admin/caregiver-interview/caregiver-interview.module'
          ).then((m) => m.CaregiverInterviewModule),
      },
      {
        path: 'client-absence',
        loadChildren: () =>
          import('app/modules/admin/client-absence/client-absence.module').then(
            (m) => m.ClientAbsenceModule
          ),
      },
      {
        path: 'incident-report',
        loadChildren: () =>
          import(
            'app/modules/admin/incident-report/incident-report.module'
          ).then((m) => m.IncidentReportModule),
      },
      {
        path: 'report-generator',
        loadChildren: () =>
          import(
            'app/modules/admin/report-generator/report-generator.module'
          ).then((m) => m.ReportGeneratorModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('app/modules/admin/setting/setting.module').then(
            (m) => m.SettingModule
          ),
      },
      {
        path: 'transport',
        loadChildren: () =>
          import('app/modules/admin/transport/transport.module').then(
            (m) => m.TransportModule
          ),
      },
      {
        path: 'client-leave',
        loadChildren: () =>
          import('app/modules/admin/client-leave/client-leave.module').then(
            (m) => m.ClientLeaveModule
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/admin/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'update-password',
        loadChildren: () =>
          import(
            'app/modules/admin/update-password/update-password.module'
          ).then((m) => m.UpdatePasswordModule),
      },
    ],
  },
];
