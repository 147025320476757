import { Graduate } from './../../services/api/models/graduate';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { snakeToTitle } from '../utils/string-conversion';
import { ClientStatus } from 'app/services/api/enums/client-status';

@Component({
  selector: 'status-badge',
  template: `<span
      *ngIf="!enableIcon"
      class="w-auto px-3 py-1 rounded uppercase text-sm font-bold"
      [ngClass]="{
        'bg-cyan-100 text-cyan-600': cyan.includes(state),
        'bg-blue-100 text-blue-600': blue.includes(state),
        'bg-emerald-100 text-emerald-600': emerald.includes(state),
        'bg-gray-200 text-gray-600': gray.includes(state),
        'bg-teal-100 text-teal-600': teal.includes(state),
        'bg-red-100 text-red-600': red.includes(state),
        'bg-indigo-100 text-indigo-600': indigo.includes(state),
        'bg-violet-100 text-violet-600': violet.includes(state),
        'bg-amber-100 text-amber-600': amber.includes(state),
        'bg-purple-100 text-purple-600': purple.includes(state),
        'bg-yellow-100 text-yellow-600': yellow.includes(state)
      }"
    >
      {{ status | uppercase }}
    </span>

    <div *ngIf="enableIcon" class="flex flex-row items-center gap-1">
      <mat-icon
        class="icon-size-2 "
        [ngClass]="{
                    'text-emerald-600': emerald.includes(state),
                    'text-red-600': red.includes(state),

                }"
        [svgIcon]="
          emerald.includes(state)
            ? 'mat_outline:check_circle_outline'
            : 'mat_outline:cancel'
        "
      >
      </mat-icon>
      <span class="w-auto px-3 py-1 rounded uppercase text-sm font-bold">
        {{ status | uppercase }}
      </span>
    </div> `,
})
export class StatusComponent implements OnInit {
  @Input() state: string = ' ';
  @Input() enableIcon: boolean;
  @Input() controlName: FormControl = null;

  public cyan = [
    'NEW',
    'REQUEST_CREATED',
    'REQUEST CREATED',
    'New Referral',
    'WITHDRAWN',
  ];
  public blue = [
    'WAITING_SUBMISSION',
    'WAITING SUBMISSION',
    'Centre Visitation',
    'ENROLLED',
    'NEW REFERRAL',
    'NEW',
    'NEW REQUEST',
  ];
  public emerald = [
    'PARENTS_SUBMITTED',
    'PARENTS SUBMITTED',
    'ACTIVE',
    'Accepted',
    'ACCEPT',
    'PRESENT',
    'Active',
    'Present',
    'SUBMITTED',
    'APPROVED',
    'STARTED',
    'OPEN',
    'OPENED',
    'COMPLETED',
    'PARENT REQUESTED',
    'FINALIZED',
    'CAREGIVER REQUESTED',
    'EI@UNDER 2 REQUESTED',
    'TEAM REQUESTED',
    'SUCCESS',
    'EMAIL SENT',
  ];
  public gray = [
    'SUBMISSION_EXPIRED',
    'SUBMISSION EXPIRED',
    'INACTIVE',
    'CANCEL',
    'DRAFT',
    'Inactive',
    'Transferred',
    'ENDED',
    'NO RESPONSE',
    'INTERIM REPORT',
    'Archived',
    'ARCHIVED',
  ];
  public teal = [
    'ASSESSED_ELIGIBLE',
    'ASSESSED ELIGIBLE',
    'COMMUNICATED',
    'COMPLETE',
    'INVITED FOR CENTRE VISIT',
    'CREATED',
    'created',
    'verified',
    'Verified',
    'VERIFIED',
    'CLOSED BY LONG ABSENCE',
  ];
  public red = [
    'ASSESSED_NOT_ELIGIBLE',
    'ASSESSED NOT ELIGIBLE',
    'ABSENT',
    'RESIGNED',
    'Resigned',
    'Absent',
    'PLACEMENT_REJECTED',
    'PLACEMENT REJECTED',
    'DECLINED',
    'Rejected',
    'ENDED',
    'Ended',
    'Expired',
    'Cancelled',
    'CANCELLED',
    'CLOSE',
    'CLOSED',
    'DELETED',
    'EXTERNAL',
    'CANCELED',
    'NOT APPROVED',
    'NOT VERIFIED',
    ClientStatus.WITHDRAWN,
    ClientStatus.GRADUATED,
    ClientStatus.DISCHARGE,
    'FAILED',
  ];
  public indigo = [
    'ASSESSED_WAITLIST',
    'FEE_FILLED',
    'IN REVIEW',
    'IN_REVIEW',
    'INVITED FOR INITIAL SCREENING',
    'PARENT_SUBMITTED',
    'PARENT SUBMITTED',
  ];
  public violet = [
    'PLACEMENT_OFFERED',
    'PLACEMENT OFFERED',
    'CM_SUBMITTED',
    'CM SUBMITTED',
    'FINANCE_SUBMITTED',
    'FINANCE SUBMITTED',
    'INVITED FOR CONTRACT SIGNING',
    'UPDATED',
    'updated',
    'Updated',
  ];
  public amber = [
    'DRAFT',
    'PENDING FOR REPORTED-BY SIGNATURE',
    'PENDING FOR CLINICAL FINDINGS',
    'PENDING FOR SCHOOL NURSE SIGNATURE',
    'PENDING FOR ACKNOWLEDGEMENTS',
    'PENDING CAREGIVER RESPONSE',
    'PENDING FOR CENTRE ADMIN ACKNOWLEDGEMENT',
    'PENDING FOR CENTRE MANAGER APPROVAL',
    'PENDING',
    'PLACEMENT_DRAFT',
    'PLACEMENT DRAFT',
    'REQUEST DRAFT',
    'Pending',
    'Reserved',
    'RECEIVED ACKNOWLEDGEMENT LETTER',
    'NOT_STARTED',
    'NOT STARTED',
    'FINANCE_DRAFT',
    'FINANCE DRAFT',
    'DRAFT',
    'PENDING FAMILY INFORMATION',
    'PENDING ROUTINES INFORMATION',
    'PENDING CAREGIVER CONSENT CONFIRMATION',
    'PENDING ACKNOWLEDGEMENTS',
    'PENDING CAREGIVER CONSENT RESPONSE',
    'PENDING INFORMATION SHARING FOLLOWING MEMBERS',
    'PENDING INFORMATION SHARING',
    'PENDING CASE MANAGER SIGNATURE',
    'PENDING SECTION C APPROVAL',
    'PENDING SECTION C SUBMISSION',
    'PENDING SECTION D SUBMISSION',
    'PENDING SECTION D APPROVAL',
    'WAITING CAREGIVER SIGNATURE',
    'REPORTING OFFICER SIGNATURE SIGNED',
    'WAITING REPORTING OFFICER SIGNATURE',
    'WAITING CAREGIVER RESPONSE',
    'PENDING CAREGIVER RESPONSE',
    'PENDING CONSENT CONFIRMATION',
    'PENDING HOME VISIT',
    'PENDING INFORMATION SHARING',
    'PENDING TEAM MEMBERS ACKNOWLEDGEMENTS',
    'PENDING CENTRE MANAGER SIGNATURE',
    'PENDING HEAD TEACHER SIGNATURE',
    'SIGNED SECTION A: REPORTED BY',
    'SIGNED SECTION A: NOTED BY',
    'SIGNED SECTION A: CHILD RETURNED',
    'SIGNED SECTION A: ACKNOWLEDGED BY',
    'SIGNED SECTION B.1: REPORTED BY',
    'SIGNED SECTION B.1: NOTED BY STAFF',
    'SIGNED SECTION B.1: NOTED BY CM',
    'SIGNED SECTION B.2: CHILD RETURNED',
    'SIGNED SECTION B.2: NOTED BY',
    'SIGNED SECTION B.2: ACKNOWLEDGED BY',
    'SIGNED SECTION C.1: REPORTED BY',
    'SIGNED SECTION C.1: NOTED BY STAFF',
    'SIGNED SECTION C.1: NOTED BY CM',
    'SIGNED SECTION C.2: CHILD RETURNED',
    'SIGNED SECTION C.2: NOTED BY',
    'SIGNED SECTION C.2: ACKNOWLEDGED BY',
    'SIGNED SECTION D: REPORTED BY',
    'SIGNED SECTION D: NOTED BY STAFF',
    'SIGNED SECTION D: ACKNOWLEDGED BY',
    'PENDING SUMMARY OF ABSENCE SIGNATURE BY CAT',
  ];
  public purple = ['PLACEMENT_ACCEPTED', 'PLACEMENT ACCEPTED'];

  public yellow = ['ONGOING', 'Ongoing', 'ongoing'];

  protected ngUnsubscribe = new Subject<void>();

  status: string = null;

  ngOnInit(): void {
    if (this.controlName) {
      this.controlName.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: (ret) => {
            if (ret) {
              this.state = ret;
              this.status = snakeToTitle(this.state.toUpperCase());
            }
          },
        });
    } else {
      this.status = snakeToTitle(this.state.toUpperCase());
    }
  }

  /* Unsubscribe and reset observable
   */
  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
