import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-note-label',
  template: `
    <div
      class="mt-10 w-full text-justify"
      [ngClass]="customClass"
      *ngIf="value"
    >
      <p class="block text-sm font-medium text-slate-500 note-content">
        <span class="font-bold">Note: </span>
        <span class="italic" [innerHTML]="value ?? '-'"></span>
      </p>
    </div>
  `,
  styles: [
    `
      .note-content {
        display: inline-block;
        text-indent: -35px;
        padding-left: 35px;
      }

      .mt-0-important {
        margin-top: 0 !important;
      }
    `,
  ],
})
export class NoteFieldComponent {
  @Input() value: string;
  @Input() customClass?: string;
}
